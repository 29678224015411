import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

import { ProgressIndicator } from '../../components'

const Home = lazy(() => import('../../pages/Home'))
const ErrorPage = lazy(() => import('../../pages/error'))
const Settings = lazy(() => import('../../pages/Settings'))
const Company = lazy(() => import('../../pages/Company'))
const CompanyOffice = lazy(() => import('../../pages/CompanyOffice'))
const Officer = lazy(() => import('../../pages/Officer'))
const BeneficialOwners = lazy(() => import('../../pages/BeneficialOwners'))
const Act = lazy(() => import('../../pages/Act'))
const Decision = lazy(() => import('../../pages/Decision'))
const CollectiveProcedure = lazy(() =>
  import('../../pages/CollectiveProcedure')
)
const Trademark = lazy(() => import('../../pages/Trademark'))
const Bdf = lazy(() => import('../../pages/Bdf'))
const Timeline = lazy(() => import('../../pages/Timeline'))
const Financial = lazy(() => import('../../pages/Financial'))
const FinancialAnalysis = lazy(() => import('../../pages/FinancialAnalysis'))
const Banking = lazy(() => import('../../pages/Banking'))
const BankAccount = lazy(() => import('../../pages/BankAccount'))
const CreditInsight = lazy(() => import('../../pages/CreditInsight'))
const Commerce = lazy(() => import('../../pages/Commerce'))
const CommerceDisputes = lazy(() => import('../../pages/CommerceDisputes'))
const CommercePayout = lazy(() => import('../../pages/CommercePayout'))
const CommerceCustomers = lazy(() => import('../../pages/CommerceCustomers'))
const CommerceProducts = lazy(() => import('../../pages/CommerceProducts'))
const CommerceSubscription = lazy(() =>
  import('../../pages/CommerceSubscription')
)
const CommerceTransaction = lazy(() =>
  import('../../pages/CommerceTransaction')
)
const DataConnections = lazy(() => import('../../pages/DataConnections'))
const Users = lazy(() => import('../../pages/Users'))
const DataSyncs = lazy(() => import('../../pages/DataSyncs'))
const Institutional = lazy(() => import('../../pages/Institutional'))
const BgCheck = lazy(() => import('../../pages/BgCheck'))
const GoogleNews = lazy(() => import('../../pages/GoogleNews'))
const CommerceOrders = lazy(() => import('../../pages/CommerceOrders'))

const Routes = () => (
  <Suspense fallback={<ProgressIndicator circular anchor="window" />}>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path={['/user', '/users']} exact component={Users} />
      <Route path="/settings" exact component={Settings} />
      <Route path="/settings/:tab" exact component={Settings} />
      <Route path="/user/:userId/company" component={Company} />
      <Route path="/user/:userId/institutional/timeline" component={Timeline} />
      <Route path="/user/:userId/institutional/bg-check" component={BgCheck} />
      <Route
        path="/user/:userId/institutional/company-officers"
        component={Officer}
      />
      <Route
        path="/user/:userId/institutional/beneficial-owners"
        component={BeneficialOwners}
      />
      <Route
        path="/user/:userId/institutional/company-offices"
        component={CompanyOffice}
      />
      <Route path="/user/:userId/institutional/acts" component={Act} />
      <Route
        path="/user/:userId/institutional/decisions"
        component={Decision}
      />
      <Route
        path="/user/:userId/institutional/collective-procedures"
        component={CollectiveProcedure}
      />
      <Route
        path="/user/:userId/institutional/trademarks"
        component={Trademark}
      />
      <Route path="/user/:userId/bdf" component={Bdf} />
      <Route path="/user/:userId/institutional" component={Institutional} />
      <Route
        path="/user/:userId/financial/analysis"
        component={FinancialAnalysis}
      />
      <Route path="/user/:userId/financial" component={Financial} />
      <Route path="/user/:userId/banking/account" component={BankAccount} />
      <Route
        path="/user/:userId/banking/credit-insight"
        component={CreditInsight}
      />
      <Route path="/user/:userId/banking" component={Banking} />
      <Route path="/user/:userId/google-news" component={GoogleNews} />
      <Route
        path="/user/:userId/commerce/disputes"
        component={CommerceDisputes}
      />
      <Route
        path="/user/:userId/commerce/customers"
        component={CommerceCustomers}
      />
      <Route
        path="/user/:userId/commerce/products"
        component={CommerceProducts}
      />
      <Route path="/user/:userId/commerce/orders" component={CommerceOrders} />
      <Route path="/user/:userId/commerce/payouts" component={CommercePayout} />
      <Route
        path="/user/:userId/commerce/transactions"
        component={CommerceTransaction}
      />
      <Route
        path="/user/:userId/commerce/subscriptions"
        component={CommerceSubscription}
      />
      <Route path="/user/:userId/commerce" component={Commerce} />
      <Route
        path="/user/:userId/data-connections"
        component={DataConnections}
      />
      <Route path="/user/:userId/data-sync" component={DataSyncs} />
      <Route>
        <ErrorPage status="404" />
      </Route>
    </Switch>
  </Suspense>
)

export default Routes
